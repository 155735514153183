<template>
  <div class="principle_3">
    <div class="overlap-group2-1">
      <div class="rectangle-30-6"></div>
      <p class="lamelioration-conti rubik-normal-orange-20px">
        L’AMELIORATION CONTINUE EST LA VOIE POUR DEVENIR LES MEILLEURS
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Principle3",
};
</script>

<style>
.principle_3,
.principle_3-1,
.principle_3-2 {
  height: 355.13px;
  position: relative;
  width: 357.13px;
}

.overlap-group2-1,
.overlap-group2-2,
.overlap-group2-3 {
  height: 355px;
  position: relative;
  width: 355px;
}

.rectangle-30-6,
.rectangle-30-7,
.rectangle-30-8 {
  background-color: var(--negroni);
  height: 251px;
  left: 52px;
  position: absolute;
  top: 52px;
  transform: rotate(-45deg);
  width: 251px;
}

.lamelioration-conti,
.lamelioration-conti-1,
.lamelioration-conti-2 {
  left: 50px;
  letter-spacing: -0.22px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 118px;
  width: 249px;
}
</style>
