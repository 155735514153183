<template>
  <div class="home_2_right-2">
    <p class="indispensables-pour-2 rubik-light-eerie-black-19px">
      Indispensables pour naviguer le monde complexe dans lequel nous vivons, les hard skills du PM existent et sont
      l’aboutissement de décennies d’innovation dans le développement logiciel et dans la gestion de projet.<br /><br />Comprendre
      les fondements de l’agilité, les techniques de recherche utilisateurs, comment faire émerger les bonnes idées,
      poser une vision, créer une stratégie produit. C’est tout cela qui fera la différence entre les produits qui
      échoueront et ceux qui feront notre quotidien de demain !
    </p>
  </div>
</template>

<script>
export default {
  name: "Home2Right3",
};
</script>

<style>
.home_2_right-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
}

.indispensables-pour-2 {
  letter-spacing: -0.21px;
  line-height: 28.5px;
  margin-top: -1px;
  position: relative;
  width: 500px;
}
</style>
