<template>
  <div class="checkbox-4">
    <div class="overlap-group-31">
      <div class="rectangle-33"></div>
      <div class="rectangle-32"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Checkbox22",
};
</script>

<style>
.checkbox-4 {
  height: 54px;
  position: relative;
  width: 53px;
}

.overlap-group-31 {
  height: 54px;
  position: relative;
}

.rectangle-33 {
  background-color: var(--hippie-blue);
  height: 50px;
  left: 3px;
  position: absolute;
  top: 4px;
  width: 50px;
}

.rectangle-32 {
  background-color: var(--link-water);
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 50px;
}
</style>
