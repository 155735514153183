<template>
  <div class="principle_1">
    <div class="overlap-group-4">
      <div class="rectangle-30"></div>
      <p class="la-collaboration-est rubik-normal-orange-20px">
        LA COLLABORATION EST LE SUPER POUVOIR D’UNE ORGANISATION
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Principle1",
};
</script>

<style>
.principle_1,
.principle_1-1,
.principle_1-2 {
  height: 355.13px;
  position: relative;
  width: 357.13px;
}

.overlap-group-4,
.overlap-group-5,
.overlap-group-6 {
  height: 355px;
  position: relative;
  width: 355px;
}

.rectangle-30,
.rectangle-30-1,
.rectangle-30-2 {
  background-color: var(--negroni);
  height: 251px;
  left: 52px;
  position: absolute;
  top: 52px;
  transform: rotate(-45deg);
  width: 251px;
}

.la-collaboration-est,
.la-collaboration-est-1,
.la-collaboration-est-2 {
  left: 53px;
  letter-spacing: -0.22px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 140px;
  width: 249px;
}
</style>
