<template>
  <div class="principle_5">
    <div class="overlap-group1-5">
      <div class="rectangle-30-9"></div>
      <p class="prendre-les-bonnes-d rubik-normal-orange-20px">PRENDRE LES BONNES DÉCISIONS AUX BONS MOMENTS</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Principle5",
};
</script>

<style>
.principle_5,
.principle_5-1,
.principle_5-2 {
  height: 355.13px;
  position: relative;
  width: 357.13px;
}

.overlap-group1-5,
.overlap-group1-6,
.overlap-group1-7 {
  height: 355px;
  position: relative;
  width: 355px;
}

.rectangle-30-9,
.rectangle-30-10,
.rectangle-30-11 {
  background-color: var(--negroni);
  height: 251px;
  left: 52px;
  position: absolute;
  top: 52px;
  transform: rotate(-45deg);
  width: 251px;
}

.prendre-les-bonnes-d,
.prendre-les-bonnes-d-1,
.prendre-les-bonnes-d-2 {
  left: 53px;
  letter-spacing: -0.22px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 133px;
  width: 249px;
}
</style>
